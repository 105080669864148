<template>
  <b-card-code
    style="margin: 20px;"
  >
    <b-card-body
      title="Reps logging Details"
      sub-title="Shows the list of representatives who accesses on caretrack"
    >
      <div class="d-flex justify-content-between  flex-wrap">

        <!-- sorting  -->
        <b-form-group>
          <b-input-group
            size="sm"
          >
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">
                  none
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group>

        <!-- filter -->
        <b-form-group>
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>
    <b-table
      ref="tableuserslogged"
      :items="items"
      :fields="fields"
      :per-page="perPage"
      :current-page="currentPage"
      responsive
      striped
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <!-- Column: Invoice Status -->
      <template #cell(User)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :style="StyleTime(data.item['Check-in'])"
              class="badge-light-warning rounded-circle b-avatar"
            >
              <feather-icon
                :icon="IconTime(data.item['Check-in'])"
              />
            </b-avatar>
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.User.toUpperCase() }}
          </span>
          <small class="text-muted">{{ data.item.Site.toLowerCase() }}</small>
        </b-media>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BMedia,
} from 'bootstrap-vue'
import usersLogged from '@core/services/usersLogged/usersLogged'
import moment from 'moment'

export default {
  components: {
    BTable,
    BCardCode,
    BFormGroup,
    BFormSelect,
    BAvatar,
    BInputGroup,
    BPagination,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BMedia,
  },
  data() {
    return {
      // selectedCheck: false,
      fields: [{ key: 'User', label: 'User', sortable: true }, { key: 'Check-in', label: 'Check-in', sortable: true }, { key: 'Client', label: 'Client', sortable: true }, { key: 'Profile', label: 'Profile', sortable: true }],
      items: [],
      perPage: 100,
      pageOptions: [100, 50, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.getUsersLogged()
    this.totalRows = this.items.length
  },
  methods: {
    getUsersLogged() {
      usersLogged.getUsersLogged({}).then(response => {
        this.items = response.data
        this.onFiltered(this.items)
      })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    IconTime(time) {
      const beginningTime = moment(time, 'h:mma')
      const endTime = moment('9:15am', 'h:mma')
      if (time === '') {
        return 'UserXIcon'
      } if (beginningTime.isBefore(endTime) || beginningTime.isSame(endTime)) {
        return 'UserCheckIcon'
      } if (beginningTime.isAfter(endTime)) {
        return 'UserMinusIcon'
      }
      return 'UserXIcon'
    },
    StyleTime(time) {
      const beginningTime = moment(time, 'h:mma')
      const endTime = moment('9:15am', 'h:mma')
      if (time === '') {
        return 'width: 32px; height: 32px; background-color: rgba(255, 229, 108, 0.3); color: #FFD410'
      } if (beginningTime.isBefore(endTime) || beginningTime.isSame(endTime)) {
        return 'width: 32px; height: 32px; background-color: rgba(152, 255, 132, 0.3); color: #26E400'
      } if (beginningTime.isAfter(endTime)) {
        return 'width: 32px; height: 32px; background-color: rgba(255, 0, 0, 0.3); color: #FF2700'
      }
      return 'width: 32px; height: 32px; background-color: rgba(255, 229, 108, 0.3); color: #FFD410'
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
