import axiosService from '../axios/axiosService'
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig'

class UsersLoggedService {
    // jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    getUsersLogged(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getUsersLogged, ...args)
    }
}

function useUsersLoggedService() {
  const usersLoggedService = new UsersLoggedService()

  return {
    usersLoggedService,
  }
}

const { usersLoggedService } = useUsersLoggedService()
export default usersLoggedService
